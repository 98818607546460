import React, { useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import TextField from "../../FormElements/TextField";
import ButtonFilled from "../../FormElements/ButtonFilled";
import transactionsApi from "../../../store/transactionsStore/transactionsApi";
import { PhoneInputField } from "../../PhoneInput";
import styles from './form.module.scss';

const Form = ({
  info,
  bonus,
  phone,
  setPhone,
  setBonus,
  setError,
  comment,
  setComment,
  error,
  setUser,
  setBonuses,
  setCurrentForm,
}) => {
  const { t } = useTranslation();
  const [searching, setSearching] = useState(false);

  const searchingUser = useCallback(() => {
    if (!info || !info.id) return setSearching(false)
    new Promise((resolve, reject) => {
      transactionsApi.getUserInfo('phone', phone.replace(/\D/g, ''), info.id)
        .then((res) => {
          const user = res.data;
          transactionsApi.getUserBonuses(info.id, info.group_id, user.id)
            .then((res) => {
              const bonuses = res.data || 0;
              resolve({user, bonuses});
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          reject(e);
        })
    })
      .then(({user, bonuses}) => {
        setUser(user);
        setBonuses(bonuses);
        setSearching(false);
        setCurrentForm(2);
      })
      .catch(() => {
        setSearching(false);
        setCurrentForm(3);
      });
  }, [phone, bonus, info]);

  const onPhoneChange = (value) => {
    setError(false);
    setPhone(value);
  }

  const onBonusChange = (e) => {
    if (typeof parseInt(e.target.value) == 'number') {
      setError(false);
      setBonus(e.target.value);
    }
  }

  const findUser = (e) => {
    e.preventDefault();

    if (+bonus <= 0) return

    setSearching(true);
    searchingUser();
  }

  return (
    <form onSubmit={findUser} className={styles.container}>
      <p className={styles.title}>{t('assessment')}</p>
      <div style={{ marginBottom: 15, zIndex: 20 }}>
        <PhoneInputField
          placeholder={t('NSAssessment.phone')}
          dropdownStyle={{ borderRadius: 0 }}
          value={phone}
          onChange={onPhoneChange}
          disabled={false}
          error={false}
        />
      </div>
      <TextField
        placeholder={t('NSAssessment.bonuses')}
        value={bonus}
        onChange={onBonusChange}
      />
      <textarea placeholder={t('comment')} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
      <ButtonFilled
        type="submit"
        className={styles.btn}
        loading={searching}
        disabled={searching}
      >{t('assess')}</ButtonFilled>
    </form>
  )
}

export default Form