import React, { useEffect, useState } from 'react';
import { FilterBranch, FilterCity } from './Filter';
import { useSelector } from "react-redux";
import styles from './Invis.css';

const Invis = (props) => {
  const {
    loading = false,
    invisParams = {},
    setInvisParams = () => {},
    isVisibleProp = false,
    disabled = false,
  } = props
  const [isVisible, setIsVisible] = useState(isVisibleProp);
  const { cities, citiesPending } = useSelector((state) => state.directoryReducer)
  const { info, pending } = useSelector((state) => state.profileReducer)

  useEffect(() => {
  }, []);

  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };

  return (
    <div className='bonusButton'>

      {isVisibleProp ? null : (
        <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
          <button onClick={toggleVisibility}>Фильтр</button>
        </div>
      )}

      {isVisible && (
        <div className="content">

          <div style={{padding: '10px 0px 10px 0px'}}>
            <FilterCity
              options={cities.map((city) => ({ value: city.id , label: city.name }))}
              loading={citiesPending}
              value={invisParams.city_ids}
              onChange={(ids) => setInvisParams({...invisParams, city_ids: ids})}
              disabled={disabled || pending || loading}
            />
          </div>

          <div>
          <FilterBranch
            options={info.branches.map((branch) => ({ value: branch.id , label: branch.name }))}
            loading={pending}
            value={invisParams.branch_ids}
            onChange={(ids) => setInvisParams({...invisParams, branch_ids: ids})}
            disabled={disabled || pending || loading}
          />
          </div>

        </div>
      )}
    </div>
  );
};

export default Invis;

