import React, {useEffect, useState} from 'react'
import ClientInfo from "./components/ClientInfo/ClientInfo";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import clientInfoActions from "../../store/clientInfoStore/clientInfoActions";
import clientsActions from "../../store/clientsStore/clientsActions";
import branchReducer from "../../store/branchStore/branchReducer";
import bonusesModalReducer from "../../store/bonusesModalStore/bonusesModalReducer";
import {ERROR, SUCCESS} from "../../store/globalStatuses/globalStatuses";
import debounce from "../../tools/debounce";
import Modal from "react-modal";
import styles from "../Clients/Clients.module.scss";
import closeModalImage from "../../components/PayModal/assets/CloseModalIcon.svg";
import ButtonFilled from "../../components/FormElements/ButtonFilled";

export default function ClientInfoContainer() {
    const {id} = useParams()
    const rfmSearch = useLocation().search.slice(1, useLocation().search.length).split('=');
    const [bonusesModal, setBonusesModal] = useState(false)
    const [branchInfo, setBranchInfo] = useState(null)
    const {customer, cStatus, transactions, tStatus, page} = useSelector(state => state.clientInfoReducer)
    const {
        status,
    } = useSelector(s => s.clientsReducer);
    const {payment_status} = useSelector(state => state.bonusesModalReducer)
    const {info} = useSelector(state => state.branchReducer)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (!branchInfo && info) setBranchInfo(info)
    }, [info]);

    useEffect(() => {
        if (payment_status === SUCCESS || payment_status === '' || payment_status === ERROR) {
            dispatch(clientInfoActions.getClientInfo(id, branchInfo))
        }

        return () => {
            dispatch(clientInfoActions.resetClientInfo())
        }
    }, [branchInfo, payment_status])

    useEffect(() => {
        if (payment_status === SUCCESS || payment_status === '' || payment_status === ERROR) {
            debounce(() => dispatch(clientInfoActions.getClientTransactions(id, page, branchInfo)), 100)
        }
    }, [page, branchInfo, payment_status])

    const changeTransactionPageAction = (page) => {
        dispatch(clientInfoActions.changeTransactionPage(page))
    }

    const getBackToClients = () => {
        if (!rfmSearch[1]) {
            history.goBack()
        } else {
            history.push(`/analytics/rfm/${rfmSearch[1]}`)
        }
    }


    return (
      <>
          <ClientInfo
            customer={customer}
            cStatus={cStatus}
            transactions={transactions && transactions.orders}
            count={transactions && transactions.count}
            tStatus={tStatus}
            page={page}
            changeTransactionPageAction={changeTransactionPageAction}
            getBackToClients={getBackToClients}
            bonusesModal={bonusesModal}
            status={status}
            setBonusesModal={setBonusesModal}
            info={info}
          />
      </>
    )
}
