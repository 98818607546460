import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import Form from "./Form";
import FoundInfo from "./FoundInfo";
import NotFoundInfo from "./NotFoundInfo";
import Success from "./Success";
import { sendBonusesToNewUser } from "../../api";
import { useSelector } from "react-redux";
import { store } from "react-notifications-component";
import styles from './bonus_modal.module.scss';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    borderRadius: 24,
  }
};

const BonusModal = (props) => {
  const { t } = useTranslation();
  const { info } = useSelector(state => state.branchReducer);

  const {
    isOpen,
    setModal,
  } = props;

  const [currentForm, setCurrentForm] = useState(1);

  const [user, setUser] = useState(null);
  const [bonuses, setBonuses] = useState(null);

  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(false);
  const [phone, setPhone] = useState('');
  const [bonus, setBonus] = useState('');
  const [comment, setComment] = useState('');

  const onModalClose = () => {
    setModal(false);
    setCurrentForm(1);
  }

  const sendBonuses = () => {
    setIsSending(true);

    sendBonusesToNewUser(info.id, {
      amount: +bonus || 0,
      comment,
      phone_number: '+' + phone.replace(/\D/g, '')
    })
      .then((res) => {
        console.log(res.data);
        setCurrentForm(4);
        setBonus('');
        setPhone('');
      })
      .catch((res) => {
        store.addNotification({
          title: `Error code: ${res ? res.code : 'NULL'}`,
          message: `${res ? res.message : t('Notify.notRespond')}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      })
      .finally(() => {
        setIsSending(false);
      })
  }

  const renderForm = () => {
    switch (currentForm) {
      case 1:
        return <Form
          bonus={bonus}
          phone={phone}
          comment={comment}
          setComment={setComment}
          setBonus={setBonus}
          setPhone={setPhone}
          setError={setError}
          error={error}
          setBonuses={setBonuses}
          setUser={setUser}
          setCurrentForm={setCurrentForm}
          info={info}
        />
      case 2:
        return <FoundInfo isSending={isSending} sendBonuses={sendBonuses}  user={user} bonus={bonus} bonuses={bonuses} />
      case 3:
        return <NotFoundInfo isSending={isSending} sendBonuses={sendBonuses} phone={phone} bonus={bonus} />
      case 4:
        return <Success closeModal={onModalClose} />
      default:
        return <Form />
    }
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={modalStyles}
      onRequestClose={onModalClose}
    >
      <div className={styles.modal}>
        <div className={styles.container}>
          {renderForm()}
        </div>
      </div>
    </Modal>
  )
}

export default BonusModal;