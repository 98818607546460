const clientsTypes = {
    GET_CLIENTS_PENDING: "GET_CLIENTS_PENDING",
    GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
    GET_CLIENTS_ERROR: "GET_CLIENTS_ERROR",

    PUT_CLIENT_PENDING: "PUT_CLIENT_PENDING",
    PUT_CLIENT_SUCCESS: "PUT_CLIENT_SUCCESS",
    PUT_CLIENT_ERROR: "PUT_CLIENT_ERROR",

    CHANGE_CHOSEN_CLIENTS:"CHANGE_CHOSEN_CLIENTS",
    CLEAR_CHOSEN_CLIENTS:"CLEAR_CHOSEN_CLIENTS",

    CHANGE_PARAMS:"CHANGE_PARAMS",
}

export default clientsTypes
