import React, { useState } from 'react';
import Modal from "react-modal";
import styles from './transactionsInfoModal.module.scss'
import TextField from "../FormElements/TextField";
import ButtonFilled from "../FormElements/ButtonFilled";
import closeModalImage from "../PayModal/assets/CloseModalIcon.svg";
import { useTranslation } from "react-i18next";
import bonusesModalActions from "../../store/bonusesModalStore/bonusesModalActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {sendBonusesToNewUser} from "../../api";
import { ref } from "yup";
import { formatDate } from "../../helpers";

const editIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M18.8571 14.28V18.8571C18.8571 19.3118 18.6765 19.7478 18.355 20.0693C18.0335 20.3908 17.5975 20.5714 17.1428 20.5714H5.14284C4.68819 20.5714 4.25215 20.3908 3.93066 20.0693C3.60917 19.7478 3.42856 19.3118 3.42856 18.8571V6.85713C3.42856 6.40247 3.60917 5.96644 3.93066 5.64495C4.25215 5.32346 4.68819 5.14284 5.14284 5.14284H9.71999M17.1428 3.42856L20.5714 6.85713L12 15.4286H8.57142V12L17.1428 3.42856Z"
      stroke="#4EB78B" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  content: {
    overflowY: 'scroll',
    maxHeight: '90vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    borderRadius: 24,
  }
};

const TransactionInfoModal = (props) => {
  const {t, i18n} = useTranslation();
  const {
    isOpen,
    setModal,
    transaction,
    setTransaction,
    getTransactions,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {isLoadingPatchOrdersRefund} = useSelector(state => state.bonusesModalReducer)
  const [amount, setAmount] = useState("");

  const onModalClose = () => {
    history.push(location.pathname);
    setModal(false);
    setTransaction(null);
    setAmount("");
  }

  const patchOrdersRefund = () => {
    if (amount) {
      dispatch(bonusesModalActions.patchOrdersRefund(transaction.id, { amount }, () => {
        setModal(false);
        setAmount("");
        getTransactions();
      }));
    }
  }

  const revertTransaction = () => {
    dispatch(bonusesModalActions.revertTransaction(transaction.id, { amount }, () => {
      setModal(false);
      setAmount("");
      getTransactions();
    }));
  }

  const completeTransaction = () => {
    dispatch(bonusesModalActions.completeTransaction(transaction.id, { bonuses: transaction.paid_from_balance, method: transaction.payment_method }, () => {
      setModal(false);
      setAmount("");
      getTransactions();
    }));
  }

  const renderStatus = () => {
    if (
      (transaction.initial_order_total_amount !== 0) && transaction.status === 'COMPLETED'
    ) {
      return <span className={styles.green}>{t("returned")}</span>
    }
    if ((transaction.initial_order_total_amount == undefined || transaction.initial_order_total_amount == 0) && transaction.status === 'COMPLETED') {
      return <span className={styles.green}>{t("received")}</span>
    }
    if (transaction.status === 'DECLINED') {
      return <span style={{color: '#FA6262'}}>{t('refused')}</span>
    }
    return <span style={{color: '#6979F8'}}>{t('NSTransactions.processing')}</span>
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={modalStyles}
      onRequestClose={onModalClose}
    >
      <div className={`${styles.modal} hide-scrollbar`}>
        <div className={styles.closeModal} onClick={onModalClose}>
          <img src={closeModalImage}/>
        </div>
        <div className={styles.container}>
          <h2>{t('NSTransModal.det')}</h2>
          {transaction && (
            <div className={styles.infoContainer}>
              <div className={styles.info}>
                <div className={styles.infoItem}>
                  <div className={styles.infoItemTitle}>
                    {t('NSRespondents.name')}:
                  </div>
                  <div className={styles.infoItemData}
                       style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    {(!transaction.sender.first_name && transaction.sender.last_name) && 'Не указано'}
                    <span className={styles.green}>{transaction.sender.first_name} {transaction.sender.last_name}</span>
                    <i
                      style={{marginLeft: "auto", cursor: "pointer"}}
                      onClick={() => {
                        if (location.pathname.includes("/analytics/bonuses")) {
                          return history.push(`/clients/client/edit/${transaction.sender.ID}?from=analytics/bonuses&transactionId=${transaction.id}`)
                        }
                        if (transaction?.sender?.ID) {
                          history.push(`/clients/client/edit/${transaction.sender.ID}?from=transaction&transactionId=${transaction.id}`)
                        }
                      }}
                    >{editIcon}</i>
                  </div>
                </div>

                <div className={styles.infoItem}>
                  <div className={styles.infoItemTitle}>
                    {t('NSAuth.phone')}:
                  </div>
                  <div className={styles.infoItemData}>
                    {transaction.sender.phone.length ? transaction.sender.phone : '-'}
                  </div>
                </div>

                <div className={styles.infoItem}>
                  <div className={styles.infoItemTitle}>
                    {t('NSTransModal.statCl')}:
                  </div>
                  <div className={styles.infoItemData}>
                    {transaction.client_type === 'RETURNED' && (<span className={styles.green}>Лояльный</span>)}
                    {transaction.client_type === 'NEW' && (<span className={styles.green}>Новый</span>)}
                  </div>
                </div>

                <div className={styles.infoItem}>
                  <div className={styles.infoItemTitle}>
                    {t('NSTransModal.sum')}:
                  </div>
                  <div className={styles.infoItemData}>
                    {transaction.order_total_amount}
                  </div>
                </div>

                <div className={styles.infoItem}>
                  <div className={styles.infoItemTitle}>
                    {t('given')}:
                  </div>
                  <div className={styles.infoItemData}>
                    {transaction.order_cashback_amount}
                  </div>
                </div>

                <div className={styles.infoItem}>
                  <div className={styles.infoItemTitle}>
                    {t('used')}:
                  </div>
                  <div className={styles.infoItemData}>
                    {transaction.paid_from_private_balance}
                  </div>
                </div>

                <div className={styles.infoItem}>
                  <div className={styles.infoItemTitle}>
                    {t('cashier')}:
                  </div>
                  <div className={styles.infoItemData}>
                    {transaction.recipient?.casher_name} &nbsp;
                    <a style={{ display: 'block', color: 'green', textDecoration: 'none' }} href={`tel:${transaction.recipient?.casher_phone}`}>
                      {transaction.recipient?.casher_phone}
                    </a>
                  </div>
                </div>

                <div className={styles.infoItem}>
                  <div className={styles.infoItemTitle}>
                    Изначальная сумма:
                  </div>
                  <div className={styles.infoItemData}>
                    {transaction.initial_order_total_amount}
                  </div>
                </div>
              </div>
              <div className={styles.info}>
                <div className={styles.infoItem}>
                  <div className={styles.infoItemTitle}>
                    {t('date')}
                  </div>
                  <div className={styles.infoItemData}>
                    {(transaction.status === 'COMPLETED' && transaction.paid_at > 0) ? new Date(transaction.paid_at).toLocaleString(`${i18n.language}-${i18n.language.toUpperCase()}`, {
                      year: 'numeric', month: 'numeric', day: 'numeric',
                      hour: 'numeric', minute: 'numeric',
                      hour12: false
                    }) : null}
                    {transaction.status === 'PENDING' ? t('tranNC') : null}
                    {transaction.status === 'DECLINED' ? t('tranRF') : null}
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoItemTitle}>
                    Дата изменения
                  </div>
                  <div className={styles.infoItemData}>
                    {transaction.updated_at ? new Date(transaction.updated_at).toLocaleString(`${i18n.language}-${i18n.language.toUpperCase()}`, {
                      year: 'numeric', month: 'numeric', day: 'numeric',
                      hour: 'numeric', minute: 'numeric',
                      hour12: false
                    }) : null}
                  </div>
                </div>

                <div className={styles.infoItem}>
                  <div className={styles.infoItemTitle}>
                    {t('NSTransModal.st2')}:
                  </div>
                  <div className={styles.infoItemData}>
                    {/*{transaction.status === 'COMPLETED' ? (*/}
                    {/*  <span className={styles.green}>{t('received')}</span>*/}
                    {/*) : transaction.status === 'DECLINED' ? (*/}
                    {/*  <span style={{color: '#FA6262'}}>{t('refused')}</span>*/}
                    {/*) : (*/}
                    {/*  <span style={{color: '#6979F8'}}>{t('NSTransactions.processing')}</span>*/}
                    {/*)}*/}
                    {renderStatus()}
                  </div>
                </div>

                <div className={styles.infoItem}>
                  <div className={styles.infoItemTitle}>
                    {t('NSTransModal.numTrans')}:
                  </div>
                  <div className={styles.infoItemData}>
                    {transaction.order_number}
                  </div>
                </div>

                <div style={{alignItems: 'flex-start'}} className={styles.infoItem}>
                  <div style={{marginTop: '16px'}} className={styles.infoItemTitle}>
                    {t('comment')}:
                  </div>
                  <div className={styles.infoItemComment}>
                    <div>
                      {transaction.comment}
                    </div>
                  </div>
                </div>

                {/*{transaction.order_cashback_amount > 0 ? (*/}
                {/*  <div className={styles.infoItem}>*/}
                {/*    <div className={styles.infoItemTitle}>*/}
                {/*      {t('bonuses')} - {t('NSBonus.birthday')}*/}
                {/*    </div>*/}
                {/*    <div className={styles.infoItemData}>*/}
                {/*      {transaction.order_cashback_amount}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*) : null}*/}
              </div>
            </div>
          )}
          {transaction && transaction.refunds && transaction.refunds?.length && (
            <>
              <h2>
                История возвратов
                {/*{t('NSTransModal.det')}*/}
              </h2>
              <table className="table">
                <thead>
                  <tr>
                    <th>Кассир</th>
                    <th>Сумма</th>
                    <th>Дата</th>
                  </tr>
                </thead>
                <tbody>
                  {transaction.refunds.map((refund, index) => (
                    <tr key={index}>
                      <td>{refund.cashier}</td>
                      <td>{refund.amount}</td>
                      <td>{formatDate(refund.date)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {transaction && (
            <div style={{marginTop: '27px'}}>
            {transaction.status === 'PENDING' && (
                <ButtonFilled onClick={completeTransaction} loading={isLoadingPatchOrdersRefund}>
                    {t('NSTransModal.complDo')}
                  </ButtonFilled>
              )}
              <div className={styles.infoItemTitle} style={{marginBottom: '24px'}}>{t('NSTransModal.sum3')}</div>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: '500',
                color: '#000',
              }}>
                <TextField style={{ height: '67px' }} type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
                <div style={{ width: '24px' }}></div>
                <ButtonFilled onClick={patchOrdersRefund} loading={isLoadingPatchOrdersRefund}>
                  {t('NSTransModal.retDo')}
                </ButtonFilled>
              </div>
              <div style={{ marginTop: 20, display: "flex", justifyContent: "flex-end", maxWidth: "440px" }}>
                <ButtonFilled variant="red" onClick={() => {
                  const a = window.confirm("Вы действительно хотите отменить транзакцию?")
                  if (a) {
                    revertTransaction();
                  }
                  return null
                }} loading={isLoadingPatchOrdersRefund}>
                  {t('fullReturn')}
                </ButtonFilled>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default TransactionInfoModal;