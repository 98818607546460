import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import DataTableTransactionsBonus from "../../../../components/TableElement/DataTableTransactionsBonus/DataTableTransactions";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import DropdownSelector from "../../../../components/Analytics/DropdownSelector/DropdownSelector";
import PayModal from 'components/PayModal';
import BonusModal from 'components/BonusModal';
import { useCurrentBranch } from "../../../../hooks/useCurrentBranch";
import { Roles } from "../../../../config";
import { PENDING } from "../../../../store/globalStatuses/globalStatuses";
import TextField from "../../../../components/FormElements/TextField";
import styles from './Transactions.module.css';
import findImg from "../../../../assets/icons/find.svg";
import Invis from "../../../../components/Filter/Invis";

export default function Transactions(props) {
    const {
        orders,
        endDate,
        page,
        setEndDate,
        setStartDate,
        startDate,
        changePageAction,
        count,
        customer,
        info,
        status,
        phone,
        setPhone,
        searchRef,
        getTransactions,
        invisParams,
        setInvisParams,
    } = props
    const [modalPay, setModalPay] = useState(false)
    const [modalBonus, setModalBonus] = useState(false)
    const { currentRole } = useCurrentBranch();
    const { t } = useTranslation();

    const AddPay = () => {
        setModalPay(true)
    }

    const AddBonus = () => {
        setModalBonus(true)
    }

    const onChangePeriod = (type) => {
        if (type === 5) {
            setStartDate(null)
            setEndDate(null)
            changePageAction(1)
        } else if (type === 4) {
            setStartDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)))
            setEndDate(new Date())
            changePageAction(1)
        } else if (type === 3) {
            setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1)))
            setEndDate(new Date())
            changePageAction(1)
        } else if (type === 2) {
            setStartDate(new Date(new Date().setDate(new Date().getDate() - 7)))
            setEndDate(new Date())
            changePageAction(1)
        } else if (type === 1) {
            setStartDate(new Date(new Date().setDate(new Date().getDate() - 1)))
            setEndDate(new Date())
            changePageAction(1)
        }
    }

    return (
      <div className={styles.container}>
          <h1>{t('NSAnalytics.bonus')}</h1>
          <div className={styles.dates}>
              {/*<DropdownSelector*/}
              {/*  status={status}*/}
              {/*  changePeriod={onChangePeriod}*/}
              {/*  trans={true}*/}
              {/*/>*/}
              {/*<div style={{marginLeft: 34, marginRight: 18}}>*/}
              {/*    <CustomDatePicker*/}
              {/*      trans={true}*/}
              {/*      tStartDate={startDate}*/}
              {/*      tEndDate={endDate}*/}
              {/*      tSetStartDate={setStartDate}*/}
              {/*      tSetEndDate={setEndDate}*/}
              {/*      changePageAction={changePageAction}*/}
              {/*    />*/}
              {/*</div>*/}
              {/*<div style={{marginLeft: 'auto', maxWidth: 450, width: '100%'}}>*/}
              <div style={{ maxWidth: 450, width: '100%'}}>
                  <TextField
                    ref={searchRef}
                    icon={findImg}
                    placeholder={t('NSTransactions.search')}
                    value={phone}
                    disabled={status === PENDING}
                    onChange={(e) => setPhone(e.target.value)}
                  />
              </div>
          </div>

          {currentRole === Roles.ANALYTIC ? (
            <div style={{ marginLeft: 'auto', width: '100%', padding: '0px 10px 24px 0px'}}>
                <Invis
                  invisParams={invisParams}
                  setInvisParams={setInvisParams}
                />
            </div>
          ) : null}

          <DataTableTransactionsBonus
            orders={orders}
            page={page}
            changePageAction={changePageAction}
            count={count}
            getTransactions={getTransactions}
          />
      </div>
    )
}
