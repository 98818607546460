import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import SearchInput from "pages/Clients/components/SearchInput/SearchInput";
import DataTableRFMClients from '../../../../components/TableElement/DataTableRFMClients/DataTableClients';
import { computePercent } from '../../../../helpers';
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import ButtonOutlined from "../../../../components/FormElements/ButtonOutlined";
import closeModalImage from "../../../../components/PayModal/assets/CloseModalIcon.svg";
import branchApi from "../../../../store/branchStore/branchApi";
import { store } from 'react-notifications-component';

import styles from './rmfClients.module.scss';

import phoneImg from './assets/images/phone.png';
import { useTranslation } from "react-i18next";
import { convertDataToTransactions } from "../../../../tools/convertDateToString";
import businessReportApi from "../../../../store/analyticsStores/businessReportStore/businessReportApi";

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    borderRadius: 24,
  }
};

export default function RFMClients() {
  const rfm = useSelector(s => s.RFMReducer);
  const { info } = useSelector(s => s.branchReducer);
  const history = useHistory();
  const { params } = useRouteMatch();
  const { t } = useTranslation()
  const clientType = params.clientType.toUpperCase();
  const [query, setQuery] = useState('');
  const [RFMStatus, setRFMStatus] = useState(null);

  const [pushMessage, setPushMessage] = useState('');
  const [bonus, setBonus] = useState(0);

  const [downloading, setDownloading] = useState(false);

  const [sendModal, setSendModal] = useState(false);
  const [bonusModal, setBonusModal] = useState(false);
  const [bonusModalStep, setBonusModalStep] = useState(1);
  const [pending, setPending] = useState(false);

  const [filteredClients, setFilteredClients] = useState([]);

  if (!rfm[clientType].length) {
    history.push('/analytics/rfm');
    return null
  }

  useEffect(() => {
    if (!RFMStatus && info && info.configuration && info.configuration.rfm_analysis) {
      setRFMStatus({
        rOne: info.configuration.rfm_analysis.recency.one,
        rTwo: info.configuration.rfm_analysis.recency.two,

        fOne: info.configuration.rfm_analysis.frequency.one,
        fTwo: info.configuration.rfm_analysis.frequency.two,

        mOne: info.configuration.rfm_analysis.monetary.one,
        mTwo: info.configuration.rfm_analysis.monetary.two,
      });
    }
  }, [info]);

  useEffect(() => {
    let clients = [];
    if (query.length) {
      clients = rfm[clientType].filter(c => c.phone.includes(query));

      if (!clients.length) clients = rfm[clientType].filter(c => {
        return `${c.first_name} ${c.last_name}`.toLowerCase().includes(query.toLowerCase())
      });

      setFilteredClients(clients);
    } else {
      setFilteredClients(rfm[clientType]);
    }
  }, [query]);

  const chooseClientAction = (id) => {
    history.replace(`/clients/client/${id}?rfm=${clientType}`);
  }

  const onSendPushToCustomers = () => {
    if (!info || !pushMessage || !rfm[clientType].length) return

    setPending(true);
    const user_ids = rfm[clientType].map((i) => i.id);
    branchApi.sendPushToCustomers(info.id, {user_ids, message: pushMessage})
        .then((res) => {
          store.addNotification({
            title: `Успешно`,
            message: `Сообщение успешно отправлено.`,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
          setPushMessage('');
        })
        .finally(() => {
          setPending(false);
          setSendModal(false);
        });
  }

  const onChangePushMessage = (e) => {
    if (e.target.value.length < 150) {
      setPushMessage(e.target.value);
    }
  }

  const onChangePrice = (e) => {
    if (isFinite(+e.currentTarget.value)) {
      // if (!info || Number(info.balance).toFixed() < +e.currentTarget.value) return
      if (!info) return
      setBonus(+e.currentTarget.value);
    }
  }

  const onSendBonusesToUsers = () => {
    if (!info || !bonus || !rfm[clientType].length) return

    setPending(true);
    const user_ids = rfm[clientType].map((i) => i.id);
    branchApi.sendBonusesToUsers(info.id, {user_ids, group_id: info.group_id, amount: bonus})
        .then((res) => {
          store.addNotification({
            title: `Успешно`,
            message: `Бонусы успешно перечислены пользователям этой группы.`,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        })
        .finally(() => {
          setPending(false);
          setBonus(0);
          setBonusModalStep(1);
          setBonusModal(false);
        });
  }

  const exportExcel = () => {
    setDownloading(true);
    businessReportApi.getStatisticsRfmAnalysisExcel(info.id, clientType)
      .finally(() => setDownloading(false));
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <SearchInput
          changePhoneAction={(v) => setQuery(v)}
          phone={query}
        />

        <div style={{display: 'flex', width: '495px'}}>
          <ButtonOutlined variant="green" color="black" onClick={() => setBonusModal(true)}>Начислить
            бонус</ButtonOutlined>
          <div style={{width: '30px'}}></div>
          <ButtonFilled onClick={() => setSendModal(true)}>Отправить уведомление</ButtonFilled>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "flex-end", marginTop: "20px"}}>
        <ButtonFilled
          onClick={exportExcel}
          loading={downloading}
        >
          {t("export")}
        </ButtonFilled>
      </div>

      <div className={styles.info}>
        <div className={styles.infoTop}>
          <h2>{rfm[clientType][0].cluster.Title}</h2>
          <b>{rfm[clientType].length}</b>
          <span>({computePercent(rfm[clientType].length, rfm.ALL_CUSTOMERS.length)}%)</span>
        </div>
        <table className={styles.infoTable}>
          <thead>
          <tr>
            <th><b>Recency</b> (Кол-во дней с последней покупки )</th>
            <th><b>Frequency</b> (Кол-во транзакций )</th>
            <th><b>Monetery</b> (Сумма покупок )</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>(недавние клиенты)  &lt; <b>{RFMStatus && RFMStatus.rOne}</b></td>
            <td>(покупает часто)   &gt; <b>{RFMStatus && RFMStatus.fOne}</b></td>
            <td>(большая сумма покупок)   &gt; <b>{RFMStatus && RFMStatus.mOne}</b></td>
          </tr>
          <tr>
            <td>
              <div className={styles.flex}>
                <div>(относительно <br/> недавние клиенты)</div>
                <b>от {RFMStatus && RFMStatus.rOne} <br/> до {RFMStatus && RFMStatus.rTwo}</b>
              </div>
            </td>
            <td>
              <div className={styles.flex}>
                <div>(покупает нечасто)</div>
                <b>от {RFMStatus && RFMStatus.fTwo} <br/> до {RFMStatus && RFMStatus.fOne}</b>
              </div>
            </td>
            <td>
              <div className={styles.flex}>
                <div>(средняя сумма покупок)</div>
                <b>от {RFMStatus && RFMStatus.mTwo} <br/> до {RFMStatus && RFMStatus.mOne}</b>
              </div>
            </td>
          </tr>
          <tr>
            <td>(давние клиенты) &gt; <b>{RFMStatus && RFMStatus.rTwo}</b></td>
            <td>(покупает очень редко) &lt; <b>{RFMStatus && RFMStatus.fTwo}</b></td>
            <td>(маленькая сумма покупок) &lt; <b>{RFMStatus && RFMStatus.mTwo}</b></td>
          </tr>
          </tbody>
        </table>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={bonusModal}
        style={modalStyles}
        onRequestClose={() => setBonusModal(false)}
      >
        <div className={styles.modal}>
          <div className={styles.closeModal} onClick={() => setBonusModal(false)}>
            <img src={closeModalImage}/>
          </div>
          <div className={styles.modalContainer}>
            {
              bonusModalStep == 1 ? (
                <>
                  <div className={styles.header}>
                    <p>Начисление бонусов</p>
                  </div>
                  <div className={styles.bonuses}>
                    <div className={styles.bonusesHeader}>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="9" r="9" fill="#E7E7E6"/>
                        <rect x="8" y="6" width="2" height="8" rx="1" fill="#A7A9AF"/>
                        <circle cx="9" cy="4" r="1" fill="#A7A9AF"/>
                      </svg>
                      <p>
                        Бонус начисляется автоматически всем клиентам в данной категории
                      </p>
                    </div>
                    <p className={styles.subTitle}>
                      Количество начисляемых бонусов:
                    </p>
                    <input
                      value={bonus}
                      className={styles.inputBonus}
                      onChange={onChangePrice}/>

                    <p className={styles.subTitle}>
                      Кол-во клиентов:
                    </p>
                    <div className={styles.bonusInput}>{rfm[clientType].length}</div>
                    <div style={{height: '20px'}}/>
                    <p className={styles.subTitle}>
                      Общая сумма к оплате:
                    </p>
                    <div className={`${styles.bonusInput} ${styles.green}`}>{bonus * rfm[clientType].length}</div>
                    {/*<div className={styles.cash}><span>Сумма спишется с текущего баланса:</span> &nbsp; <b>*/}
                    {/*  {*/}
                    {/*    info && info.balance ?*/}
                    {/*        Number(info.balance) % 1 !== 0 ?*/}
                    {/*            Number(info.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") :*/}
                    {/*            info.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0*/}
                    {/*  }*/}
                    {/*</b></div>*/}

                    <ButtonFilled
                      className={styles.btn}
                      disabled={!bonus}
                      onClick={() => {
                        setBonusModalStep(2);
                      }}
                    >Продолжить</ButtonFilled>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.header}>
                    <div style={{cursor: 'pointer'}} onClick={() => setBonusModalStep(1)}>
                      <svg style={{marginRight: 'auto'}} width="23" height="23" viewBox="0 0 23 23" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <rect width="2.70393" height="15.6376" rx="1.35196"
                              transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 13.0293 2.64099)"
                              fill="#AFAFAB"/>
                        <rect x="12.9746" y="21.1027" width="2.62964" height="15.6376" rx="1.31482"
                              transform="rotate(135 12.9746 21.1027)" fill="#AFAFAB"/>
                      </svg>
                    </div>
                  </div>
                  <div className={styles.bonusesResult}>
                    <div><span>Начисляемые бонусы:</span> <span>{bonus}</span></div>
                    {/*<div><span>Доступный баланс:</span> <span>*/}
                    {/*  {*/}
                    {/*    info && info.balance ?*/}
                    {/*        Number(info.balance) % 1 !== 0 ?*/}
                    {/*            Number(info.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") :*/}
                    {/*            info.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0*/}
                    {/*  }*/}
                    {/*</span></div>*/}
                    <div><span>Кол-во клиентов:</span> <span>{rfm[clientType].length}</span></div>

                    <div className={styles.bonusesResultItem}><b>К оплате:</b>
                      <span>{bonus * rfm[clientType].length}</span></div>

                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      margin: 'auto auto 0',
                      height: '70%'
                    }}>
                      <ButtonFilled
                        className={styles.btn}
                        loading={pending}
                        onClick={onSendBonusesToUsers}
                      >{t('NSClients.submitPayment')}</ButtonFilled>
                    </div>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={sendModal}
        style={modalStyles}
        onRequestClose={() => setSendModal(false)}
      >
        <div className={styles.modal}>
          <div className={styles.closeModal} onClick={() => setSendModal(false)}>
            <img src={closeModalImage}/>
          </div>
          <div className={styles.modalContainer}>
            <div className={styles.header}>
              <p>Отправить уведомление</p>
            </div>

            <div>
              <p className={styles.p}>Текст уведомления</p>
              <textarea
                placeholder="Введите текст"
                className={styles.textArea}
                value={pushMessage}
                onChange={onChangePushMessage}
                required
              />
              <div className={styles.span} style={{color: `${pushMessage.length >= 149 ? 'tomato' : '#000000'}`}}>не
                более 150 символов
              </div>

              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '16px'}}>
                <img width="230" src={phoneImg} style={{marginBottom: '16px'}}/>
                <ButtonFilled
                  className={styles.btn}
                  onClick={onSendPushToCustomers}
                  loading={pending}
                >Отправить</ButtonFilled>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <DataTableRFMClients
        customers={filteredClients}
        count={2}
        page={1}
        change1PageAction={() => {
        }}
        status={''}
        chooseClientAction={chooseClientAction}
      />
    </div>
  )
}
