import React from 'react'
import styles from './General.module.css'
import Numbers from "../../../../../../components/Analytics/Numbers/Numbers";
import DropdownSelector from "../../../../../../components/Analytics/DropdownSelector/DropdownSelector";
import ChartBar from "../ChartBar/ChartBar";
import EmptyData from "../../../../../../components/Analytics/EmptyData/EmptyData";
import { useTranslation } from "react-i18next";
import { useCurrentBranch } from "../../../../../../hooks/useCurrentBranch";
import { Roles } from "../../../../../../config";
import Invis from "../../../../../../components/Filter/Invis";

export default function General(props) {
    const {
      average_check,
      cashback_amount,
      data,
      payment_total,
      transactions,
      type,
      changePeriod,
      status,
      chart_data,
      payment_from_private_balance,
      dinamicSalesDateFrom,
      dinamicSalesDateTo,
      changeDate,
      invisParams,
      setInvisParams,
    } = props
    const { t } = useTranslation();
    const { currentRole } = useCurrentBranch()

    return (
        <div className={styles.container}>
            <h1>{t('NSSidebar.sales')}</h1>
            {currentRole === Roles.ANALYTIC ? (
              <div style={{ marginLeft: 'auto', width: '100%', padding: '24px 10px 0 0px'}}>
                <Invis
                  invisParams={invisParams}
                  setInvisParams={setInvisParams}
                />
              </div>
            ) : null}
            <div className={styles.numbers}>
                {average_check && cashback_amount && payment_total && transactions ?
                    <Numbers
                        average_check={average_check}
                        cashback_amount={cashback_amount}
                        payment_total={payment_total}
                        transactions={transactions}
                        payment_from_private_balance={payment_from_private_balance}
                    />
                    :
                    <EmptyData marginTop={20}/>
                }


                {/*<DropdownSelector*/}
                {/*    changePeriod={changePeriod}*/}
                {/*    status={status}*/}
                {/*    type={type}*/}
                {/*/>*/}
            </div>
            <ChartBar
                average_check={average_check}
                cashback_amount={cashback_amount}
                payment_total={payment_total}
                transactions={transactions}
                type={type}
                chart_data={chart_data}
                changePeriod={changePeriod}
                dinamicSalesDateFrom={dinamicSalesDateFrom}
                dinamicSalesDateTo={dinamicSalesDateTo}
                changeDate={changeDate}
            />
        </div>

    )
}
