import instance from "../../api";
import queryString from "querystring";

const clientsApi = {
    getClients: (id, params = {}) => {
        const newParams = {};
        Object.keys(params).forEach((key) => {
            if (params[key] !== null && params[key] !== "") {
                newParams[key] = params[key];
            }
        })
        const prms = queryString.stringify(newParams, '&', '',{
            skipNull: true,
            skipEmptyString: true
        })

        return instance().get(`/branches/${id}/customers?${prms}`).then(res => res.data)
    },
    getAnalyticClients: (groupId, params) => {
        const newParams = {};
        Object.keys(params).forEach((key) => {
            if (params[key] !== null && params[key] !== "") {
                newParams[key] = params[key];
            }
        })
        const prms = queryString.stringify(newParams, '&', '',{
            skipNull: true,
            skipEmptyString: true,
        })

        return instance().get(`/branches/group/${groupId}/customers?${prms}`).then(res => res.data)
    },
    getClientsDatabase: (id, params = {}) => {
        const newParams = {};
        Object.keys(params).forEach((key) => {
            if (params[key] !== null && params[key] !== "") {
                newParams[key] = params[key];
            }
        })
        const prms = queryString.stringify(newParams, '&', '',{
            skipNull: true,
            skipEmptyString: true
        })

        return instance().get(`/branches/${id}/customers/uploaded?${prms}`).then(res => res.data)
    },
    getAnalyticClientsDatabase: (groupId, params = {}) => {
        const newParams = {};
        Object.keys(params).forEach((key) => {
            if (params[key] !== null && params[key] !== "") {
                newParams[key] = params[key];
            }
        })
        const prms = queryString.stringify(newParams, '&', '',{
            skipNull: true,
            skipEmptyString: true,
        })

        return instance().get(`/branches/group/${groupId}/customers/uploaded?${prms}`).then(res => res.data)
    },
    putClient: (userId, body = {}) => {
        return instance().put(`/customers/${userId}/personal-info`, body).then(res => res?.data)
    }
}

export default clientsApi
